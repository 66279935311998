// const BASE_URL = '/api/';
// const BASE_URL = 'http://dev.moetopravo.bg/api/';
// const BASE_URL = 'http://beta.moetopravo.bg/api/';
const BASE_URL = 'https://moetopravo.bg/api/';

export const environment = {

  base_url: BASE_URL,

  // User and Professional
  user_registration: BASE_URL + 'user',
  user_login: BASE_URL + 'user/login',
  user_edit: BASE_URL + 'user',
  change_password: BASE_URL + 'user/change/password',
  professional_registration: BASE_URL + 'professionalist',
  professional_login: BASE_URL + 'professionalist/login',
  change_password_pro: BASE_URL + 'professionalist/change/password',
  professional_edit: BASE_URL + 'professionalist',
  signal: BASE_URL + 'public/signal',
  professionals_by_type: BASE_URL + 'public/professionalist/',
  all_algorithms: BASE_URL + 'algorithm/',
  all_news: BASE_URL + 'public/news',
  all_topics: BASE_URL + 'public/post/pagination/',
  add_topic: BASE_URL + 'post',
  edit_topic: BASE_URL + 'post',
  delete_topic: BASE_URL + 'post/',
  all_comments: BASE_URL + 'public/comment/pagination/',
  add_comment: BASE_URL + 'public/comment',
  edit_comment: BASE_URL + 'comment',
  delete_comment: BASE_URL + 'comment/',
  topic_up_vote: BASE_URL + 'post/upvote',
  topic_down_vote: BASE_URL + 'post/downvote',
  comment_up_vote: BASE_URL + 'comment/upvote',
  comment_down_vote: BASE_URL + 'comment/downvote',
  all_private_topics: BASE_URL + 'private/post/pagination',
  add_private_topic: BASE_URL + 'private/post',
  all_private_comments: BASE_URL + 'private/comment/pagination/',
  add_private_comment: BASE_URL + 'private/comment',
  edit_private_topic: BASE_URL + 'private/post',
  delete_private_topic: BASE_URL + 'private/post/',
  edit_private_comment: BASE_URL + 'private/comment',
  delete_private_comment: BASE_URL + 'private/comment/',
  reset_password: BASE_URL + 'token/reset/password',
  forgotten_password_user: BASE_URL + 'user/reset/password',
  forgotten_password_professional: BASE_URL + 'professionalist/reset/password',

  // Admin
  admin_registration: BASE_URL + 'admin',
  admin_edit_account: BASE_URL + 'admin/permission',
  admin_login: BASE_URL + 'admin/login',
  admin_edit: BASE_URL + 'admin',
  admin_change_password: BASE_URL + 'admin/change/password',
  get_admins: BASE_URL + 'admin/pagination',
  delete_admin: BASE_URL + 'admin/',
  get_users: BASE_URL + 'user/pagination',
  ban_user: BASE_URL + 'user/change/ban/status',
  get_professionals: BASE_URL + 'public/professionalist/',
  ban_professionals: BASE_URL + 'professionalist/change/ban/status',
  get_news: BASE_URL + 'public/news/pagination',
  add_news: BASE_URL + 'news',
  delete_news: BASE_URL + 'news/',
  add_algorithm: BASE_URL + 'algorithm',
  edit_algorithm: BASE_URL + 'algorithm',
  upload_news_image: BASE_URL + 'file/',
  forgotten_password_admin: BASE_URL + 'admin/reset/password',
  confirm_password: BASE_URL + 'admin/master'
}

export const accessibility = {
  fontSizes: [
    ' ', '1em', '1.1em', '1.2em', '1.3em', '1.4em', '1.5em', '1.6em', '1.7em', '1.8em', '1.9em',
    '2em', '2.1em', '2.2em', '2.3em', '2.4em', '2.5em', '2.6em', '2.7em', '2.8em', '2.9em'
  ],
  fonts: [
    { value: 'Arial', viewValue: 'Arial' },
    { value: 'Times', viewValue: 'Times' },
    { value: 'Courier', viewValue: 'Courier' },
    { value: 'Lato', viewValue: 'Lato' },
    { value: 'Open Sans', viewValue: 'Open Sans' },
    { value: 'Roboto Slab', viewValue: 'Roboto Slab' }
  ]
}

export const professionals = {
  type: [
    { value: 0, viewValue: 'Lawyer' },
    { value: 1, viewValue: 'Arbitrator' },
    { value: 2, viewValue: 'Mediator' },
    { value: 3, viewValue: 'Notary' },
    { value: 4, viewValue: 'Bailiff' },
    { value: 5, viewValue: 'Jurist' },
    { value: 6, viewValue: 'Ombudsman' },
  ]
}

export const fileType = {
  type: [
    { value: 0, viewValue: 'News Attachment' },
    { value: 1, viewValue: 'About Us Attachment' },
    { value: 2, viewValue: 'Good Practices_Attachment' },
    { value: 3, viewValue: 'Public Post' },
    { value: 4, viewValue: 'Public Comment' },
    { value: 5, viewValue: 'Private Post' },
    { value: 6, viewValue: 'Private Comment' },
  ]
}

export const tokenUserTypes = {
  type: [
    { value: 0, viewValue: 'User Password' },
    { value: 1, viewValue: 'Professional Password' },
    { value: 2, viewValue: 'Admin Password' }
  ]
}

export class ConstantsService {


}